<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="上账明细" name="detail"></el-tab-pane>
      <el-tab-pane label="操作记录" name="record"></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'detail',
    };
  },
  methods: {
    handleClick(e) {
      this.$emit('change', e.name);
    },
  },
};
</script>

<style></style>
