import TablePage from '../../../../../components/table_page';
import Form from './form';
import Detail from './form/detail';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {

      },
      configs: {
        formConfig: {
        },
      },
      modalConfig: {},
      params: {
        configCode: 'fee_pool_detail_page_2',
      },
    };
  },
  components: {
    Form,
    Detail,
  },
  created() {
    this.getConfigList('fee_pool_detail_page_list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
    clickVisible({ val, row }) {
      if (val.code === 'dms_thaw') {
        if (row.freezeAmount > 0) {
          return true;
        }
        return false;
      }
      if (val.code === 'dms_frozen') {
        if (row.usableAmount > 0) {
          return true;
        }
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      this.formConfig = {
        ...val,
        row,
      };
      if (val.code === 'make_ account') {
        this.formName = 'Form';
        this.modalConfig.title = '上账';
        this.openModal();
      }
      if (val.code === 'adjustment') {
        this.formName = 'Form';
        this.modalConfig.title = '调整';
        this.openModal();
      }
      if (val.code === 'getDetail') {
        this.formName = 'Detail';
        this.formConfig.code = 'view';
        this.modalConfig.title = '查看详情';
        this.openFull();
      }
      if (val.code === 'dms_thaw') {
        this.formName = 'Form';
        this.modalConfig.title = '解冻';
        this.openModal();
      }
      if (val.code === 'dms_frozen') {
        this.formName = 'Form';
        this.modalConfig.title = '冻结';
        this.openModal();
      }
    },
  },
};
