import TablePage from '../../../../../components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {

      },
      configs: {
        formConfig: {
          // item: [
          //   {
          //     field: 'operationType',
          //     title: '操作类型',
          //     type: 'select',
          //     apiUrl: '/fee/feePoolController/getFeePoolPageConfig?configCode=fee_pool_detail_log_goods_page_list',
          //     optionsKey: {
          //       label: 'positionLevelName',
          //       value: 'positionLevelCode',
          //     },
          //   },
          // ],
        },
      },
      modalConfig: {},
      params: {
        configCode: 'fee_pool_detail_log_goods_page_list',
      },
    };
  },
  created() {
    this.getConfigList('fee_pool_detail_log_page_list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
  },
};
