import TablePage from '../../../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    poolCode: String,
  },
  data() {
    return {
      formConfig: {

      },
      configs: {
        formConfig: {
        },
      },
      modalConfig: {},
      params: {
        poolCode: this.poolCode,
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.getConfigList('fee_pool_line_operation_page_list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
  },
};
