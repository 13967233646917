import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import costDetailAccountDetailTable from './detail_table';

formCreate.component('costDetailAccountDetailTable', costDetailAccountDetailTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'fee_pool_line_detail_line_detail_form',
      customerName: '',
      poolTypeList: [],
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'detailTable') {
        item.props = {
          ...item.props,
          poolDetailCode: this.formConfig.row.poolDetailCode,
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      request.get('/fee/feePoolDetailController/queryByPoolDetailCode', { poolDetailCode: this.formConfig.row.poolDetailCode }).then((r) => {
        if (r.success) {
          const res = r;
          res.result.goodsProductCode = res.result.goodsProductCode ? res.result.goodsProductCode.split(',') : [];
          this.setValue(res.result);
        }
      });
    },
  },
};
