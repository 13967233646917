import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import costDetailAccountDetailTable from './components/detail_table';
import costDetailAccountRecordTable from './components/record_table';
import detailTabs from './components/tab.vue';

formCreate.component('costDetailAccountDetailTable', costDetailAccountDetailTable);
formCreate.component('costDetailAccountRecordTable', costDetailAccountRecordTable);
formCreate.component('detailTabs', detailTabs);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'fee_pool_line_detail_form',
      customerName: '',
      poolTypeList: [],
      configCode: '',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'detailTable') {
        item.props = {
          ...item.props,
          poolCode: this.formConfig.row.poolCode,
        };
      }
      if (item.field === 'recordTable') {
        item.props = {
          ...item.props,
          poolCode: this.formConfig.row.poolCode,
        };
      }
      if (item.field === 'detailTable') {
        item.on = {
          ...item.on,
          reload: () => {
            this.getDetail();
          },
        };
      }
      if (item.field === 'tabs') {
        item.on = {
          ...item.on,
          change: (e) => {
            if (e === 'record') {
              this.hiddenFields(true, 'detailTable');
              this.hiddenFields(false, 'recordTable');
            } else {
              this.hiddenFields(true, 'recordTable');
              this.hiddenFields(false, 'detailTable');
            }
          },
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(true, 'recordTable');
      this.getDetail();
      // 获取下拉数据
      request.get('/fee/feePoolController/getFeePoolPageConfig', { configCode: 'fee_pool_page_list' }).then((res) => {
        if (res.success) {
          this.configCode = res.result.lineDetailAdjustConfigCode;
          const detailTable = this.getRule('detailTable');
          detailTable.props = {
            ...detailTable.props,
            configCode: this.configCode,
          };
        }
      });
    },
    // 获取详情数据
    getDetail() {
      request.get('/fee/feePoolController/queryByPoolCode', { poolCode: this.formConfig.row.poolCode }).then((result) => {
        if (result.success) {
          const res = result;
          res.result.goodsProductCode = res.result.goodsProductCode ? res.result.goodsProductCode.split(',') : [];
          this.setValue(res.result);
        }
      });
    },
  },
};
